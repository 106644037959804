<template>
    <div class="modal fade" id="fieldsPositionModal" tabindex="-1" aria-labelledby="fieldsPositionModal"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-transparent">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body pb-5 px-sm-4 mx-12">
                    <h1 class="address-title text-center mb-1">Управление полями</h1>

                    <div class="row">
                        <div class="col-md-6 col-12 mb-md-0 mb-4">
                            <p>Доступные поля</p>

                            <ul class="list-group list-group-flush" id="handle-list-1">

                                <li class="list-group-item lh-1 d-flex justify-content-between align-items-center"
                                    v-for="column in columns">
                              <span class="d-flex justify-content-between align-items-center w-100">
                                  <div>
                                     <vue-feather type="menu"
                                                  class="ti ti-menu-2 align-text-bottom me-2"/>
                                <span>{{ column }}</span>
                                  </div>
                                  <form-switcher @change="columnVisibilityChange(column)"
                                                 :value="activeCols.includes(column)"/>
                              </span>
                                </li>


                            </ul>
                        </div>
                        <div class="col-md-6 col-12 mb-md-0 mb-4">
                            <p>Отображаемые поля</p>
                            <ul class="list-group list-group-flush" id="sortable-list">
                                <li class="list-group-item lh-1 d-flex justify-content-between align-items-center"
                                    v-for="(item,index) in items" :key="index" :data-name="item">
                                  <span class="d-flex justify-content-between align-items-center">
                                    <vue-feather type="menu"
                                                 class="drag-handle cursor-move ti ti-menu-2 align-text-bottom me-2"/>
                                    <span>{{ item }}</span>
                                  </span>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    name: "CrudColumnManager",
    data: function () {
        return {
            items: this.activeCols.length ? this.activeCols : []
        }
    },
    props: {
        columns: {
            type: Array,
            default() {
                return []
            }
        },
        activeCols: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        columnVisibilityChange(column) {
            if (!this.items.includes(column)) {
                this.items.push(column)
            } else {
                this.items.splice(this.items.indexOf(column), 1)
            }

            window.livewire.emit('columnChange', this.items);
        }
    },
    components: {

    },
    mounted() {
        var self=this;
        var el = document.getElementById('sortable-list');
        var sortable = Sortable.create(el,{
            onSort: function(evt) {
                let items =[]
                var nodes = el.childNodes;
                for(var i=0; i<nodes.length; i++) {
                    if (nodes[i].nodeName.toLowerCase() == 'li') {
                        items.push(nodes[i].dataset.name)
                    }
                }
                window.livewire.emit('columnChange', items);

            }
        });
    }

}
</script>

<style scoped>

</style>
