export default {
    template: `<div class="d-flex align-items-center">
    <div class="avatar me-50">
        <img :src="user.avatar" v-if="user" alt="avatar images" width="32" height="32">
    </div>
    <h6 class="mb-0" v-if="user">{{ user.name }}</h6>
    </div>`,
    data: function () {
        return {
            user: null
        }
    },
    mounted() {
        if (typeof this.params.value === 'object') {
            this.user = this.params.value;
        } else {
            this.user = this.params.data.who_status_object;
        }
    }
};
