<template>
    <div class="card">
        <div class="card-body border-bottom d-flex justify-content-between">
            <div>
                <div class="btn-group me-1" v-show="selectedRows.length>0">
                    <button type="button"
                            class="btn btn-primary btn-sm dropdown-toggle waves-effect waves-float waves-light"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Выделенные ({{ selectedRows.length }})
                        <span class="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"
                              v-show="checkProcess"></span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#" @click.prevent="checkSelectedRows">Проверить</a>
                        <a class="dropdown-item" href="#" @click.prevent="deleteSelectedRows">Удалить</a>
                        <a class="dropdown-item" href="#" @click.prevent="copyToClipboard">Скопировать</a>
                    </div>
                </div>

                <div class="btn-group me-1">
                    <button type="button"
                            class="btn btn-primary btn-sm dropdown-toggle waves-effect waves-float waves-light"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Фильтры
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({use_type: 'privat'})">FB Индивидуальные</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({use_type: 'share'})">FB Общие</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({use_type: 'share',status_for:'fb',use_status:'false'})">FB Инд.
                            Новые</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({status_for:'mail',connection_status:'true'})">Mail
                            Активные</a>
                    </div>
                </div>

                <div class="btn-group me-1" v-if="filters.length>0">
                    <button type="button"
                            class="btn btn-outline-primary btn-sm"
                            @click.prevent="clearFilters">
                        Сбросить фильтры
                    </button>
                </div>

            </div>

            <div>
                <slot name="button"></slot>
                <button class="btn btn-icon btn-outline-primary waves-effect ml-2"
                        @click.prevent="checkSelectedRows(true)">
                    <div class="spinner-border text-primary" role="status" :style="{width: '1.2rem', height: '1.2rem'}"
                         aria-hidden="true" v-if="checkProcess"></div>
                    <vue-feather type="refresh-cw" size="16" v-else></vue-feather>
                </button>
            </div>

        </div>
        <div class="card-datatable table-responsive pt-0">
            <ag-grid-vue
                style="width: 100%;height: 70vh;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :defaultColDef="defaultColDef"
                @cell-value-changed="onCellValueChanged"
                @grid-ready="onGridReady"
                :animateRows="true"
                domLayout="autoHeight"
                @column-moved="onColumnMoved"
                @column-resized="onColumnResized"
                row-selection="multiple"
                suppress-row-click-selection="true"
                @row-selected="onRowSelected"
            >
            </ag-grid-vue>
        </div>

    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";

import DatePickerRenderer from "./cell-renderers/DatePickerRenderer";
import UseStatusRenderer from "./cell-renderers/UseStatusRenderer";
import UseTypeRenderer from "./cell-renderers/UseTypeRenderer";
import StatusForRenderer from "./cell-renderers/StatusForRenderer";
import StatusWhereRenderer from "./cell-renderers/StatusWhereRenderer";
import VersionRenderer from "./cell-renderers/VersionRenderer";
import ConnectionStatusRenderer from "./cell-renderers/ConnectionStatusRenderer";
import GeoRenderer from "./cell-renderers/GeoRenderer";

export default {
    name: "AgGridProxyList",
    inject: ["getAgGridData", "$onCellValueChanged", "$onColumnResized", "$onColumnMoved"],
    props: {
        title: {
            type: String,
            default: "Proxy List"
        },

        data: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            filters: [],
            columnDefs: [],
            rowData: [],
            defaultCols: [
                {
                    field: 'id',
                    headerName: 'id',
                    editable: false,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    headerCheckboxSelection: true,
                    filter: 'agNumberColumnFilter',
                },
                {
                    field: 'add_date', headerName: 'add_date', cellClass: 'dateISO',
                    editable: true,
                    cellEditor: 'DatePicker',
                    cellEditorPopup: true,
                    valueFormatter: (params) => {
                        let date = new Date(params.value);
                        let day = date.getDate().toString().padStart(2, '0');
                        let month = (date.getMonth() + 1).toString().padStart(2, '0');
                        let year = date.getFullYear().toString();
                        let hour = date.getHours().toString().padStart(2, '0');
                        let minute = date.getMinutes().toString().padStart(2, '0');
                        let second = date.getSeconds().toString().padStart(2, '0');
                        return day + '.' + month + '.' + year + ' ' + hour + ':' + minute + ':' + second;
                    }
                },
                {
                    field: 'exp_date', headerName: 'exp_date',
                    editable: false,
                },
                {
                    field: 'use_status',
                    headerName: 'use_status',
                    editable: false,
                    cellRenderer: 'UseStatusRenderer',
                    cellEditorPopup: true,
                },
                {
                    field: 'use_type',
                    headerName: 'use_type',
                    editable: false,
                    cellRenderer: 'UseTypeRenderer',
                    cellEditorPopup: true
                },
                {
                    field: 'status_for',
                    headerName: 'status_for',
                    editable: false,
                    cellRenderer: 'StatusForRenderer',
                },
                {
                    field: 'geo',
                    headerName: 'geo',
                    editable: false,
                    cellRenderer: 'GeoRenderer',
                },
                {
                    field: 'status_where',
                    headerName: 'status_where',
                    editable: true,
                    cellRenderer: 'StatusWhereRenderer',
                },
                {
                    field: 'version',
                    headerName: 'version',
                    editable: false,
                    cellRenderer: 'VersionRenderer',
                },
                {
                    field: 'connection_status',
                    headerName: 'connection_status',
                    editable: false,
                    cellRenderer: 'ConnectionStatusRenderer',
                },
                {
                    field: 'last_check',
                    headerName: 'last_check',
                    editable: false,
                }
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
                flex: 1,
                minWidth: 100,
                floatingFilter: true
            },
            gridApi: null,
            gridColumnApi: null,
            selectedRows: [],
            checkProcess: false,

        };
    },
    methods: {
        clearFilters() {
            this.gridApi.setFilterModel(null);
            this.filters = [];
            axios.post('/dashboard/api/user/settings-update', {
                key: 'proxy_filters',
                value: ''
            })
        },
        getUserFilters() {
            axios.get('/dashboard/api/user/settings', {
                params: {
                    key: 'proxy_filters'
                }
            })
                .then(response => {

                    if (response.status == 200) {
                        this.filters = typeof response.data.data === 'object' && response.data.data !== null ? response.data.data : [];
                        this.applyFilters()
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
        },
        applyFilters() {
            this.gridApi.setFilterModel(null);
            let filtersObj = {};
            if (this.filters.length > 0)
                this.filters.forEach((item) => {
                    filtersObj[item.model] = {
                        type: item.type,
                        filter: item.filter
                    }
                });

            this.gridApi.setFilterModel(filtersObj);
        },
        setFilter(filters = {}, single = true) {
            if (single) this.filters = [];

            for (const filterKey in filters) {
                let find = this.filters.find(item => item.model === filterKey);
                if (find) {
                    this.filters.splice(this.filters.indexOf(find), 1);
                }
                this.filters.push({model: filterKey, type: 'equals', filter: filters[filterKey]});
            }

            this.applyFilters();

            axios.post('/dashboard/api/user/settings-update', {
                key: 'proxy_filters',
                value: this.filters
            })
        },
        checkSelectedRows(all = false) {
            this.checkProcess = true;
            axios
                .post('/dashboard/api/check-proxies', {
                    ids: all === true ? [] : this.gridApi.getSelectedRows().map(row => row.id),
                })
                .then(response => {
                    if (response.status == 200) {
                        toastr['success']('Процесс проверки завершен!', 'Успех!', {
                            closeButton: true,
                            tapToDismiss: false,
                            rtl: false
                        });


                        this.getAgGridData('/dashboard/api/proxy', this.defaultCols, this.gridApi)
                        this.selectedRows = [];
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
                .finally(() => {
                    this.checkProcess = false;
                });
        },
        copyToClipboard() {
            let rows = this.gridApi.getSelectedRows();
            if (!rows.length) return;

            let text = '';
            for (let i = 0; i < rows.length; i++) {
                text += rows[i].full_proxy + '\r';
            }

            let eTempInput = document.createElement('textarea');
            eTempInput.value = text;
            document.body.appendChild(eTempInput);
            eTempInput.select();
            document.execCommand('copy');
            document.body.removeChild(eTempInput);

            toastr['success']('Данные успешно скопированы в буфер обмена!', 'Успех!', {
                closeButton: true,
                tapToDismiss: false,
                rtl: false
            });
        },
        deleteSelectedRows() {
            this.$swal({
                title: 'Вы уверенны?',
                text: 'Это действие необратимо!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Подтверждаю действие!',
                cancelButtonText: 'Нет, хочу отменить!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    axios
                        .post('/dashboard/api/proxy/delete', {
                            ids: this.selectedRows.map(row => row.id)
                        })
                        .then(response => {
                            if (response.status == 200) {
                                const sel = this.gridApi.getSelectedRows();
                                this.gridApi.applyTransaction({remove: sel});
                                this.$swal({
                                    title: 'Удалено!',
                                    text: 'Выделенные строки были удалены.',
                                    type: 'success',
                                    showCloseButton: true,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }
                        })
                        .catch(({response}) => {
                            if (response && response.status != 200) {

                            }
                        })
                        .finally(() => {
                            // действие когда запрос полностью завершен
                        });

                }
            });

        },
        onRowSelected(event) {
            this.selectedRows = this.gridApi.getSelectedRows();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.getAgGridData('/dashboard/api/proxy', this.defaultCols, this.gridApi).then(() => {
                this.getUserFilters()
                this.gridApi.sizeColumnsToFit();
            });
        },
        onCellValueChanged(params) {
            this.$onCellValueChanged(params, '/dashboard/api/proxy/update');
        },
        onColumnMoved(params) {
            this.$onColumnMoved(params, '/dashboard/api/account/set-proxies-order');
        },
        onColumnResized(params) {
            this.$onColumnResized(params, '/dashboard/api/proxy/set-column-size');
        },
    },
    mounted() {
        window.addEventListener('onProxyAdded', (e) => {
            this.getAgGridData('/dashboard/api/proxy', this.defaultCols, this.gridApi)
        });

    },
    components: {
        AgGridVue,
        DatePicker: DatePickerRenderer,
        UseStatusRenderer: UseStatusRenderer,
        UseTypeRenderer: UseTypeRenderer,
        StatusForRenderer: StatusForRenderer,
        StatusWhereRenderer: StatusWhereRenderer,
        VersionRenderer: VersionRenderer,
        ConnectionStatusRenderer: ConnectionStatusRenderer,
        GeoRenderer: GeoRenderer,
    }

}
</script>

<style lang="scss">
.use-status-renderer, .status-for-renderer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.geo-renderer {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 100%;

    img {
        width: 20px;
        margin-right: 6px;
    }
}
</style>
