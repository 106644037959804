export default {
    template: `<span f-if="typeof statuses[params.value] !=='undefined'" class="status-for-renderer"
                     :style="statuses[params.value]['style']">
    <vue-feather :type="statuses[params.value]['icon']" size="18"></vue-feather>
  </span>`,
    data: function () {
        return {
            statuses: {
                'true': {
                    icon: 'plus-circle',
                    style: {
                        color: 'var(--bs-success)'
                    }
                },
                'false': {
                    icon: 'minus-circle',
                    style: {
                        color: 'var(--bs-danger)'
                    }
                },
                'check': {
                    icon: 'loader',
                    style: {
                        color: 'var(--bs-warning)'
                    }
                },
            }
        }
    }
};
