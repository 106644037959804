<template>
    <div class="card">
        <div class="card-body border-bottom d-flex justify-content-between">
            <div>
                <div class="btn-group me-1" v-show="selectedRows.length>0">
                    <button type="button"
                            class="btn btn-primary btn-sm dropdown-toggle waves-effect waves-float waves-light"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Выделенные ({{ selectedRows.length }})
                        <span class="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"
                              v-show="checkProcess"></span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#" @click.prevent="deleteSelectedRows">Удалить выделенные</a>
                        <a class="dropdown-item" href="#changeAccountStatus" data-bs-toggle="modal">Изменить статус</a>
                    </div>
                </div>

                <div class="btn-group me-1">
                    <button type="button"
                            class="btn btn-primary btn-sm dropdown-toggle waves-effect waves-float waves-light"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Роль аккаунта
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({model:'acc_role', type: 'equals', filter: 'main'})">Главный</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({model:'acc_role', type: 'equals', filter: 'secondary'})">Вторичный</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({model:'acc_role', type: 'equals', filter: 'teh'})">Технический</a>

                    </div>
                </div>

                <div class="btn-group me-1">
                    <button type="button"
                            class="btn btn-primary btn-sm dropdown-toggle waves-effect waves-float waves-light"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Тип аккаунта
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({model:'acc_type', type: 'equals', filter: 'PZRD'})">ПЗРД</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({model:'acc_type', type: 'equals', filter: 'FARM'})">Фарм</a>
                        <a class="dropdown-item" href="#"
                           @click.prevent="setFilter({model:'acc_type', type: 'equals', filter: 'AUTOREG'})">Автореги</a>
                    </div>
                </div>

                <div class="btn-group me-1" v-if="filters.length>0">
                    <button type="button"
                            class="btn btn-outline-primary btn-sm"
                            @click.prevent="clearFilters">
                        Сбросить фильтры ({{ filters.length }})
                    </button>
                </div>

                <div class="btn-group me-1">
                    <button type="button"
                            class="btn btn-primary btn-sm"
                            data-bs-toggle="modal" data-bs-target="#generateMail"
                    >
                        Сгенерировать почту
                    </button>
                </div>


            </div>

            <div>
                <slot name="button"></slot>
                <button class="btn btn-icon btn-outline-primary waves-effect ml-2"
                        @click.prevent="updateAccounts">
                    <div class="spinner-border text-primary" role="status" :style="{width: '1.2rem', height: '1.2rem'}"
                         aria-hidden="true" v-if="checkProcess"></div>
                    <vue-feather type="refresh-cw" size="16" v-else></vue-feather>
                </button>
            </div>

        </div>
        <div class="card-datatable table-responsive pt-0">
            <ag-grid-vue
                :pagination="true"
                :paginationPageSize="20"
                style="width: 100%;height:70vh;"
                class="ag-theme-alpine"
                :rowData="rowData"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                @cell-value-changed="onCellValueChanged"
                @grid-ready="onGridReady"
                :animateRows="true"
                domLayout="autoHeight"
                @column-moved="onColumnMoved"
                @column-resized="onColumnResized"
                row-selection="multiple"
                suppress-row-click-selection="true"
                @row-selected="onRowSelected"
            >
            </ag-grid-vue>
        </div>

    </div>
    <div class="modal fade text-start" id="changeAccountStatus" tabindex="-1" aria-modal="true" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Массовое изменение статуса</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <label class="form-label" for="select2Demo">Выберите статус</label>

                    <select v-model="pickupStatus" class="select2InModal form-select select2-hidden-accessible"
                            id="select2Demo"
                            data-select2-id="select2Demo" tabindex="-1" aria-hidden="true">
                        <option>Выберите из списка</option>
                        <option :value="status" v-for="status in pickupStatuses">{{ status }}</option>
                    </select>
                </div>
                <div class="modal-footer">
                    <button @click.prevent="changeStatusSelectedRows" type="button"
                            class="btn btn-primary waves-effect waves-float waves-light"> Изменить
                        <span class="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"
                              v-show="pickupStatusProcess"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <AccountGenerateMailModal/> <!-- Modal -->
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";

import DatePickerRenderer from "./cell-renderers/DatePickerRenderer";
import UseStatusRenderer from "./cell-renderers/UseStatusRenderer";
import UseTypeRenderer from "./cell-renderers/UseTypeRenderer";
import StatusForRenderer from "./cell-renderers/StatusForRenderer";
import StatusWhereRenderer from "./cell-renderers/StatusWhereRenderer";
import VersionRenderer from "./cell-renderers/VersionRenderer";
import ConnectionStatusRenderer from "./cell-renderers/ConnectionStatusRenderer";
import GeoRenderer from "./cell-renderers/GeoRenderer";
import cellUserSelectRenderer from "./cell-renderers/cellUserSelectRenderer";
import cellLinkRenderer from "./cell-renderers/cellLinkRenderer";
import cellBadgeRenderer from "./cell-renderers/cellBadgeRenderer";
import AccountGenerateMailModal from "./AccountGenerateMailModal";

export default {
    name: "AgGridAccountsList",
    inject: ["getAgGridData", "$onCellValueChanged", "$onColumnMoved", "$onColumnResized"],
    props: {
        title: {
            type: String,
            default: "Proxy List"
        },

        data: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => {
            }
        },
        users: {
            type: Array,
            default: () => []
        },
        userId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            pickupStatuses: ['NEW', 'ERROR', 'READY', 'BAN', 'USE', 'USE_NOW'],
            pickupStatus: null,
            pickupStatusProcess: false,
            filters: [],
            columnDefs: [],
            rowData: [],
            defaultCols: [
                {
                    field: 'id',
                    headerName: 'id',
                    "editable": false,
                    filter: 'agNumberColumnFilter',
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    headerCheckboxSelection: true,
                },
                {
                    field: 'acc_name',
                    headerName: 'acc_name',
                    resizable: true

                },
                {
                    field: 'add_date', headerName: 'add_date', cellClass: 'dateISO',
                    editable: true,
                    cellEditor: 'DatePickerRenderer',
                    cellEditorPopup: true,
                    valueFormatter: (params) => {
                        let date = new Date(params.value);
                        let day = date.getDate().toString().padStart(2, '0');
                        let month = (date.getMonth() + 1).toString().padStart(2, '0');
                        let year = date.getFullYear().toString().substring(2);
                        let hour = date.getHours().toString().padStart(2, '0');
                        let minute = date.getMinutes().toString().padStart(2, '0');
                        let second = date.getSeconds().toString().padStart(2, '0');
                        return day + '.' + month + '.' + year + ' ' + hour + ':' + minute + ':' + second;
                    }
                },
                {
                    field: 'acc_role',
                    headerName: 'acc_role',
                    cellEditor: 'agSelectCellEditor',
                    cellRenderer: 'cellBadgeRenderer',
                    cellEditorPopup: false,
                    cellEditorParams: {
                        values: ['main', 'secondary', 'teh'],
                        cellHeight: 20,
                        formatValue: value => value.toUpperCase(),
                        cellRenderer: 'cellBadgeRenderer',
                        searchDebounceDelay: 500
                    }
                },
                {
                    field: 'acc_type',
                    cellRenderer: 'cellBadgeRenderer',
                    headerName: 'acc_type',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorPopup: false,
                    cellEditorParams: {
                        values: ['PZRD', 'AUTOREG', 'FARM'],
                        cellHeight: 20,
                        formatValue: value => value.toUpperCase(),
                        cellRenderer: 'cellBadgeRenderer',
                        searchDebounceDelay: 500
                    }
                },
                {
                    field: 'status_pickup',
                    cellRenderer: 'cellBadgeRenderer',
                    headerName: 'status_pickup',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorPopup: false,
                    cellEditorParams: {
                        values: ['NEW', 'ERROR', 'READY', 'BAN', 'USE', 'USE_NOW'],
                        cellHeight: 20,
                        formatValue: value => value.toUpperCase(),
                        cellRenderer: 'cellBadgeRenderer',
                        searchDebounceDelay: 500
                    }
                },
                {
                    field: 'who_status',
                    headerName: 'who_status',
                    cellRenderer: 'cellUserSelectRenderer',
                    cellEditor: 'agSelectCellEditor',
                    cellEditorPopup: false,
                    valueFormatter: (params) => {

                        if (params.value !== null && typeof params.value === 'object') {
                            return params.value.name;
                        } else if (params.value !== null
                            && typeof params.data === 'object'
                            && typeof params.data.who_status_object === 'object' && params.data.who_status_object !== null) {
                            return params.data.who_status_object.name;
                        }
                        return params.value;
                    },
                    // valueParser: function (params) {
                    //     let obj = JSON.parse(params.newValue)
                    //     return obj.id;
                    // },

                    cellEditorParams: {
                        values: this.users,
                        cellHeight: 20,
                        formatValue: (value) => {
                            console.log(value);
                            return value.id;
                        },
                        searchDebounceDelay: 500,

                    }
                },
                {field: 'cookies_fb', headerName: 'cookies_fb', cellEditor: 'agLargeTextCellEditor'},
                {field: 'mail_cookie', headerName: 'mail_cookie', cellEditor: 'agLargeTextCellEditor'},
                {field: 'screen_ads', headerName: 'screen_ads', cellRenderer: 'cellLinkRenderer'},
                {field: 'screen_profile', headerName: 'screen_profile', cellRenderer: 'cellLinkRenderer'},
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
                // flex: 1,
                minWidth: 100,
                floatingFilter: true,
            },
            gridApi: null,
            gridColumnApi: null,
            selectedRows: [],
            checkProcess: false,

        };
    },
    watch: {
        filters: {
            handler: function (val, oldVal) {
                this.gridApi.setFilterModel(null);
                let filters = {};
                if (val.length > 0)
                    val.forEach((item) => {
                        filters[item.model] = {
                            type: item.type,
                            filter: item.filter
                        }
                    });

                this.gridApi.setFilterModel(filters);
            },
            deep: true
        },

    },
    methods: {
        clearFilters() {
            this.filters = [];
            axios.post('/dashboard/api/user/settings-update', {
                key: 'account_filters',
                value: ''
            })
        },
        updateAccounts() {
            this.getAgGridData('/dashboard/api/account/' + (this.userId || ''), this.defaultCols, this.gridApi)
        },
        changeStatusSelectedRows(status) {
            if (this.pickupStatus === null || this.selectedRows.length === 0) return;

            this.pickupStatusProcess = true;

            axios
                .post('/dashboard/api/account/set-status', {
                    ids: this.selectedRows.map(row => row.id),
                    status: this.pickupStatus,
                })
                .then(response => {
                    if (response.status == 200) {
                        this.selectedRows = [];
                        this.getAgGridData('/dashboard/api/account/' + (this.userId || ''), this.defaultCols, this.gridApi)

                        $('#changeAccountStatus').modal('hide');

                        // show toast
                        toastr['success'](response.data.message, response.data.title, {
                            closeButton: true,
                            tapToDismiss: false,
                            rtl: false
                        });
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
                .finally(() => {
                    this.pickupStatusProcess = false;
                });

        },
        setFilter(filter) {
            let find = this.filters.find(item => item.model === filter.model);
            if (find) {
                this.filters.splice(this.filters.indexOf(find), 1);
            }
            this.filters.push(filter);



            axios.post('/dashboard/api/user/settings-update', {
                key: 'account_filters',
                value: this.filters
            })
        },
        getUserFilters() {
            axios.get('/dashboard/api/user/settings', {
                params: {
                    key: 'account_filters'
                }
            })
                .then(response => {

                    if (response.status == 200) {
                        this.filters = typeof response.data.data === 'object' && response.data.data !== null ? response.data.data : [];
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
        },
        checkSelectedRows(all = false) {
            this.checkProcess = true;
            axios
                .post('/dashboard/api/update-account', {
                    accounts: all === true ? [] : this.gridApi.getSelectedRows().map(row => row.id),
                })
                .then(response => {
                    if (response.status == 200) {
                        toastr['success']('Процесс обновления завершен!', 'Успех!', {
                            closeButton: true,
                            tapToDismiss: false,
                            rtl: false
                        });

                        this.getAgGridData('/dashboard/api/account', this.defaultCols, this.gridApi)
                        this.selectedRows = [];
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
                .finally(() => {
                    this.checkProcess = false;
                });
        },
        copyToClipboard() {
            let rows = this.gridApi.getSelectedRows();
            if (!rows.length) return;

            let text = '';
            for (let i = 0; i < rows.length; i++) {
                text += rows[i].full_proxy + '\r';
            }

            let eTempInput = document.createElement('textarea');
            eTempInput.value = text;
            document.body.appendChild(eTempInput);
            eTempInput.select();
            document.execCommand('copy');
            document.body.removeChild(eTempInput);

            toastr['success']('Данные успешно скопированы в буфер обмена!', 'Успех!', {
                closeButton: true,
                tapToDismiss: false,
                rtl: false
            });
        },
        deleteSelectedRows() {
            this.$swal({
                title: 'Вы уверенны?',
                text: 'Это действие необратимо!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Подтверждаю!',
                cancelButtonText: 'Отменить!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    axios
                        .post('/dashboard/api/account-delete', {
                            ids: this.selectedRows.map(row => row.id)
                        })
                        .then(response => {
                            if (response.status == 200) {
                                const sel = this.gridApi.getSelectedRows();
                                this.gridApi.applyTransaction({remove: sel});
                                this.$swal({
                                    title: 'Удалено!',
                                    text: 'Выделенные строки были удалены.',
                                    type: 'success',
                                    showCloseButton: true,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }
                        })
                        .catch(({response}) => {
                            if (response && response.status != 200) {

                            }
                        })
                        .finally(() => {
                            // действие когда запрос полностью завершен
                        });

                }
            });

        },
        onRowSelected(event) {
            this.selectedRows = this.gridApi.getSelectedRows();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.getAgGridData('/dashboard/api/account/' + (this.userId || ''), this.defaultCols, this.gridApi)
                .then(() => {
                    this.getUserFilters();
                });
        },
        onCellValueChanged(params) {
            this.$onCellValueChanged(params, '/dashboard/api/account-update');
        },
        onColumnMoved(params) {
            this.$onColumnMoved(params, '/dashboard/api/account/set-columns-order');

        },
        onColumnResized(params) {
            this.$onColumnResized(params, '/dashboard/api/account/set-column-size');
        },
    },
    mounted() {

    },
    components: {
        AgGridVue,
        DatePickerRenderer: DatePickerRenderer,
        UseStatusRenderer: UseStatusRenderer,
        UseTypeRenderer: UseTypeRenderer,
        StatusForRenderer: StatusForRenderer,
        StatusWhereRenderer: StatusWhereRenderer,
        VersionRenderer: VersionRenderer,
        ConnectionStatusRenderer: ConnectionStatusRenderer,
        GeoRenderer: GeoRenderer,
        cellUserSelectRenderer: cellUserSelectRenderer,
        cellLinkRenderer: cellLinkRenderer,
        cellBadgeRenderer: cellBadgeRenderer,
        AccountGenerateMailModal: AccountGenerateMailModal,
    }

}
</script>

<style lang="scss">
.ag-root-wrapper {
    border: none;
}
.use-status-renderer, .status-for-renderer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.geo-renderer {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 100%;

    img {
        width: 20px;
        margin-right: 6px;
    }
}
</style>
