export default {
    template: `<span><span class="badge rounded-pill" :class="statuses[params.value]">{{ params.value }}</span></span>`,
    data: function () {
        return {
            statuses: {
                ACTIVE:'badge-light-success',
                DISABLED: 'badge-light-danger',
                UNSETTLED: 'badge-light-warning',
                PENDING_RISK_REVIEW: 'badge-light-warning',
                PENDING_SETTLEMENT:'badge-light-warning',
                IN_GRACE_PERIOD:'badge-light-warning',
                PENDING_CLOSURE: 'badge-light-info',
                CLOSED: 'badge-light-info',
                ANY_ACTIVE: 'badge-light-info',
                ANY_CLOSE: - 'badge-light-info'
            }
        }
    },
    beforeMount() {
    },
};
