<template>
    <div class="card">
        <div class="card-body border-bottom d-flex justify-content-between">
            <div>


            </div>

            <div>
                <slot name="button"></slot>
            </div>

        </div>
        <div class="card-datatable table-responsive pt-0">
            <ag-grid-vue
                style="width: 100%;height: 70vh;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :rowData="rowData"
                :defaultColDef="defaultColDef"
                @cell-value-changed="onCellValueChanged"
                @grid-ready="onGridReady"
                :animateRows="true"
                domLayout="autoHeight"
                @column-moved="onColumnMoved"
                @column-resized="onColumnResized"
                row-selection="multiple"
                suppress-row-click-selection="true"
                @row-selected="onRowSelected"
            >
            </ag-grid-vue>
        </div>

    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";

import DatePickerRenderer from "./cell-renderers/DatePickerRenderer";
import UseStatusRenderer from "./cell-renderers/UseStatusRenderer";
import UseTypeRenderer from "./cell-renderers/UseTypeRenderer";
import StatusForRenderer from "./cell-renderers/StatusForRenderer";
import StatusWhereRenderer from "./cell-renderers/StatusWhereRenderer";
import VersionRenderer from "./cell-renderers/VersionRenderer";
import ConnectionStatusRenderer from "./cell-renderers/ConnectionStatusRenderer";
import GeoRenderer from "./cell-renderers/GeoRenderer";

export default {
    name: "AgGridPlatforms",
    inject: ["getAgGridData", "$onCellValueChanged", "$onColumnResized", "$onColumnMoved"],
    props: {
        title: {
            type: String,
            default: "Proxy List"
        },

        data: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            filters: [],
            columnDefs: [],
            rowData: [],
            defaultCols: [
                {
                    field: 'id',
                    headerName: 'id',
                    editable: false,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    headerCheckboxSelection: true,
                    filter: 'agNumberColumnFilter',
                    width: 100,
                },
                {
                    field: 'name', headerName: 'name',
                    editable: true,
                },
            ],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
                editable: true,
                flex: 1,
                minWidth: 100,
                floatingFilter: true
            },
            gridApi: null,
            gridColumnApi: null,
            selectedRows: [],
            checkProcess: false,

        };
    },
    methods: {
        clearFilters() {
            this.gridApi.setFilterModel(null);
            this.filters = [];
            axios.post('/dashboard/api/user/settings-update', {
                key: 'proxy_filters',
                value: ''
            })
        },
        getUserFilters() {
            axios.get('/dashboard/api/user/settings', {
                params: {
                    key: 'proxy_filters'
                }
            })
                .then(response => {

                    if (response.status == 200) {
                        this.filters = typeof response.data.data === 'object' && response.data.data !== null ? response.data.data : [];
                        this.applyFilters()
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
        },
        applyFilters() {
            this.gridApi.setFilterModel(null);
            let filtersObj = {};
            if (this.filters.length > 0)
                this.filters.forEach((item) => {
                    filtersObj[item.model] = {
                        type: item.type,
                        filter: item.filter
                    }
                });

            this.gridApi.setFilterModel(filtersObj);
        },
        setFilter(filters = {}, single = true) {
            if (single) this.filters = [];

            for (const filterKey in filters) {
                let find = this.filters.find(item => item.model === filterKey);
                if (find) {
                    this.filters.splice(this.filters.indexOf(find), 1);
                }
                this.filters.push({model: filterKey, type: 'equals', filter: filters[filterKey]});
            }

            this.applyFilters();

            axios.post('/dashboard/api/user/settings-update', {
                key: 'proxy_filters',
                value: this.filters
            })
        },
        checkSelectedRows(all = false) {
            this.checkProcess = true;
            axios
                .post('/dashboard/api/check-proxies', {
                    ids: all === true ? [] : this.gridApi.getSelectedRows().map(row => row.id),
                })
                .then(response => {
                    if (response.status == 200) {
                        toastr['success']('Процесс проверки завершен!', 'Успех!', {
                            closeButton: true,
                            tapToDismiss: false,
                            rtl: false
                        });


                        this.getAgGridData('/dashboard/api/proxy', this.defaultCols, this.gridApi)
                        this.selectedRows = [];
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {

                    }
                })
                .finally(() => {
                    this.checkProcess = false;
                });
        },
        copyToClipboard() {
            let rows = this.gridApi.getSelectedRows();
            if (!rows.length) return;

            let text = '';
            for (let i = 0; i < rows.length; i++) {
                text += rows[i].full_proxy + '\r';
            }

            let eTempInput = document.createElement('textarea');
            eTempInput.value = text;
            document.body.appendChild(eTempInput);
            eTempInput.select();
            document.execCommand('copy');
            document.body.removeChild(eTempInput);

            toastr['success']('Данные успешно скопированы в буфер обмена!', 'Успех!', {
                closeButton: true,
                tapToDismiss: false,
                rtl: false
            });
        },
        deleteSelectedRows() {
            this.$swal({
                title: 'Вы уверенны?',
                text: 'Это действие необратимо!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Подтверждаю действие!',
                cancelButtonText: 'Нет, хочу отменить!',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    axios
                        .post('/dashboard/api/proxy/delete', {
                            ids: this.selectedRows.map(row => row.id)
                        })
                        .then(response => {
                            if (response.status == 200) {
                                const sel = this.gridApi.getSelectedRows();
                                this.gridApi.applyTransaction({remove: sel});
                                this.$swal({
                                    title: 'Удалено!',
                                    text: 'Выделенные строки были удалены.',
                                    type: 'success',
                                    showCloseButton: true,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                            }
                        })
                        .catch(({response}) => {
                            if (response && response.status != 200) {

                            }
                        })
                        .finally(() => {
                            // действие когда запрос полностью завершен
                        });

                }
            });

        },
        onRowSelected(event) {
            this.selectedRows = this.gridApi.getSelectedRows();
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            this.getAgGridData('/dashboard/api/platforms', this.defaultCols, this.gridApi).then(() => {
                this.getUserFilters()
                this.gridApi.sizeColumnsToFit();
            });
        },
        onCellValueChanged(params) {
            this.$onCellValueChanged(params, '/dashboard/api/platforms/update');
        },
        onColumnMoved(params) {
            this.$onColumnMoved(params, '/dashboard/api/platforms/save-ordering');
        },
        onColumnResized(params) {
            this.$onColumnResized(params, '/dashboard/api/platforms/save-sizes');
        },
    },
    mounted() {
        window.addEventListener('onProxyAdded', (e) => {
            this.getAgGridData('/dashboard/api/proxy', this.defaultCols, this.gridApi)
        });

    },
    components: {
        AgGridVue,
        DatePicker: DatePickerRenderer,
        UseStatusRenderer: UseStatusRenderer,
        UseTypeRenderer: UseTypeRenderer,
        StatusForRenderer: StatusForRenderer,
        StatusWhereRenderer: StatusWhereRenderer,
        VersionRenderer: VersionRenderer,
        ConnectionStatusRenderer: ConnectionStatusRenderer,
        GeoRenderer: GeoRenderer,
    }

}
</script>

<style lang="scss">
.use-status-renderer, .status-for-renderer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.geo-renderer {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 100%;

    img {
        width: 20px;
        margin-right: 6px;
    }
}
</style>
