import {mapActions} from "vuex";

export default {
    template: `
        <div class="pages-renderer__wrap">
        <div class="pages-renderer" v-if="firstPage">
            <img :src="firstPage.logo" :alt="firstPage.name" v-if="firstPage.logo">
            <div class="pages-renderer__body">
                <a target="_blank" class="pages-renderer__name">
                    <span>{{ firstPage.name }}</span>
                </a>
                <div class="pages-renderer__id">{{ firstPage.app_id }}</div>
            </div>
        </div>
        </div>`,
    data: function () {
        return {
            firstPage: {},

        };
    },
    beforeMount() {
        this.firstPage = this.params.data.app_active
            ? this.params.value.find(app => app.id === this.params.data.app_active)
            : this.params.value[0];
        // console.log(this.params);
    }
};
