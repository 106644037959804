<template>
    <div>
        <div class="modal fade text-start" id="generateMail" tabindex="-1" aria-labelledby="myModalLabel1"
             aria-modal="true" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Генерация почты</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-success mb-1" role="alert" v-if="status=='success'">
                            <h4 class="alert-heading">Успешно!</h4>
                            <p class="px-1">{{ success_message }}</p>
                        </div>

                        <div class="alert alert-danger mb-1" role="alert" v-else-if="status== 'error'">
                            <h4 class="alert-heading">Ошибка!</h4>
                            <p class="px-1">{{ success_message }}</p>
                        </div>

                        <Select2 v-model="account_id" :options="accounts"
                                 :settings="{dropdownParent:'#generateMail',placeholder:'Выберите из списка'}"/>

                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                @click.prevent="submit"
                                :disabled="!account_id"
                                class="btn btn-primary waves-effect waves-float waves-light">
                            Генерировать
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                  v-show="process"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountGenerateMailModal",
    props: {},
    data() {
        return {
            account_id: '',
            status: '',
            success_message: '',
            process: false,
            accounts: [],
        }
    },
    methods: {
        submit() {
            this.process = true;
            this.success_message = '';
            axios.post('/dashboard/api/account/generate-mail', {
                account_id: this.account_id,
            }).then(response => {
                this.status = 'success';
                this.success_message = response.data.message;
            }).catch(error => {
                this.status = 'error';
                this.success_message = error.response.data.message;
            }).finally(() => {
                this.process = false;
            });
        },
        getAccounts() {
            axios.get('/dashboard/api/account/', {
                params: {}
            }).then(response => {
                if (response.status === 200) {
                    this.accounts = response.data.data.items.map(item => {
                        return {
                            id: item.id,
                            text: item.acc_name
                        }
                    });
                }

            })

        },
    },
    mounted() {
        this.getAccounts()
    }
}
</script>

<style>
.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
}
</style>
