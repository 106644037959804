export default {
    template: `<span><span v-if="params.value!=='NONE'" class="badge rounded-pill" :class="statuses[params.value]">{{ params.value }}</span></span>`,
    data: function () {
        return {
            statuses: {
                ADS_INTEGRITY_POLICY: 'badge-light-danger',
                ADS_IP_REVIEW:  'badge-light-danger',
                RISK_PAYMENT:  'badge-light-danger',
                GRAY_ACCOUNT_SHUT_DOWN: 'badge-light-danger',
                ADS_AFC_REVIEW:  'badge-light-danger',
                BUSINESS_INTEGRITY_RAR: 'badge-light-danger',
                PERMANENT_CLOSE: 'badge-light-danger',
                UNUSED_RESELLER_ACCOUNT: 'badge-light-danger'

            }
        }
    },
    beforeMount() {
    },
};
