export default {
    template: `<span f-if="params.value" class="use-type-renderer badge" :class="{'badge-light-success':params.value==='privat','badge-light-danger':params.value!=='privat'}">{{ types[params.value] }}</span>`,
    data: function () {
        return {
            types:{
                privat: 'Индивидуальные',
                share: 'Общие',
            }
        }
    }
};
