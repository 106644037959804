<template>
    <div>
        <div class="card" style="zoom: 1;">
            <div class="card-header">
                <a class="h4 card-title" @click.prevent="isOpen=!isOpen" style="flex: 1 0 0;">{{ account.acc_name }}
                    <span
                        class="badge  rounded-pill ms-auto me-1"
                        :class="{'badge-light-success': account.status_dolphin==='ACTIVE','badge-light-danger': account.status_dolphin!=='ACTIVE'}">{{
                            account.status_dolphin
                        }}</span></a>
                <div class="heading-elements">
                    <ul class="list-inline mb-0">
                        <li>
                            <a @click.prevent="isOpen=!isOpen" class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-chevron-down">
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a @click="setAccount"
                               data-bs-toggle="modal" data-bs-target="#accountSettingsModal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-settings">
                                    <circle cx="12" cy="12" r="3"></circle>
                                    <path
                                        d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                                </svg>
                            </a>
                            <a data-action="reload" @click="updateAccount()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" class="feather feather-rotate-cw">
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-content collapse" :class="{show:isOpen}">
                <div class="card-body">
                    <ag-grid-vue
                        style="width: 100%;"
                        class="ag-theme-alpine"
                        :columnDefs="columnDefs"
                        :rowData="rowData"
                        :defaultColDef="defaultColDef"
                        @cell-value-changed="onCellValueChanged"
                        @grid-ready="onGridReady"
                        :animateRows="true"
                        domLayout="autoHeight"
                        @column-moved="onColumnMoved"
                        @column-resized="onColumnResized"
                    >
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";
import {reactive, onMounted, ref} from "vue";
import CreditCardRenderer from "./cell-renderers/CreditCardRenderer.js";
import CountryRenderer from "./cell-renderers/CountryRenderer";
import PagesRenderer from "./cell-renderers/PagesRenderer";
import DisableReasonRenderer from "./cell-renderers/DisableReasonRenderer";
import CabinetStatusRenderer from "./cell-renderers/CabinetStatusRenderer";
import AppsRenderer from "./cell-renderers/AppsRenderer";
import {mapGetters, mapActions} from "vuex";

export default {
    name: "AgGridCabinet",
    components: {
        AgGridVue,
        CreditCardRenderer: CreditCardRenderer,
        CountryRenderer: CountryRenderer,
        PagesRenderer: PagesRenderer,
        DisableReasonRenderer: DisableReasonRenderer,
        CabinetStatusRenderer: CabinetStatusRenderer,
        AppsRenderer: AppsRenderer
    },
    props: {
        cabinets: {
            type: Array,
            default: () => []
        },
        account: {
            type: Object,
            default: () => {
            }
        },
        isOpen: {
            type: Number,
            default: 0
        },
        columnsPosition: {
            type: Object,
            default: () => {
            }
        },
        columnsWidth: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            processUpdateAccount: false,
            acc_display: this.account.acc_display,
            gridApi: null,
            gridColumnApi: null,
            rowData: this.copyArray(this.cabinets),
            defaultColDef: {
                resizable: true,
                editable: true,
                width: 100
            },

        }
    },

    setup() {
    },
    computed: {
        ...mapGetters(['countries', 'showPageModal', 'pages']),
        hiddenColumns() {
            let cols = ['created_at', 'updated_at', 'id', 'account_type', 'page_active', 'app_active'];
            if (this.acc_display == 'link') {
                cols = ['app_naming', 'deeplink', ...cols]
            } else if (this.acc_display == 'app') {
                cols = ['domain', 'ad_link', ...cols]
            }
            return cols;
        },
        columnDefs() {
            let self = this;
            if (!self.rowData.length) return [];
            let firstCabinet = self.rowData[0];
            let columns = Object.keys(firstCabinet).filter(function (key) {
                return self.hiddenColumns.indexOf(key) === -1;
            }).map(key => {

                let template = {
                    headerName: key,
                    field: key,
                    sortable: true,
                    filter: true,
                    checkboxSelection: false,
                    headerCheckboxSelection: false,
                    editable: true,
                }


                let index = self.columnsPosition.indexOf(key);
                if (self.columnsPosition.length > 0 && index !== -1) {
                    template['index'] = index;
                }


                if (Object.keys(self.columnsWidth).length > 0 && typeof self.columnsWidth[key] !== 'undefined') {
                    template.width = self.columnsWidth[key];
                }

                if (['external_id', 'account_id'].includes(key)) {
                    template.editable = false;
                }

                if (key == 'card_type') {
                    template.cellRenderer = 'CreditCardRenderer'
                    template.cellEditor = 'agSelectCellEditor'
                    template.cellEditorParams = {
                        values: [
                            'mastercard',
                            'visa'
                        ],
                    }
                }

                if (key == 'pages') {
                    template.cellRenderer = 'PagesRenderer';
                    template.editable = true;
                    template.minWidth = 240;
                    template.cellEditor = 'agSelectCellEditor'
                    template.valueSetter = (params) => {
                        let pages = this.cabinets.find(cabinet => cabinet.id === params.data.id).pages;
                        params.data.pages = typeof pages === 'object' ? pages : [];
                        params.data.page_active = typeof pages === 'object' ? pages.find(page => page.name === params.newValue).id : '';
                        return true;
                    }
                    template.cellEditorParams = {
                        values: firstCabinet.pages.map(page => page.name),
                    }
                }

                if (key == 'apps') {
                    template.cellRenderer = 'AppsRenderer';
                    template.editable = true;
                    template.minWidth = 240;
                    template.cellEditor = 'agSelectCellEditor'
                    template.valueSetter = (params) => {
                        let apps = this.cabinets.find(cabinet => cabinet.id === params.data.id).apps;
                        params.data.apps = typeof apps === 'object' ? apps : [];
                        params.data.app_active = typeof apps === 'object' ? apps.find(app => app.name === params.newValue).id : '';
                        return true;
                    }
                    template.cellEditorParams = {
                        values: firstCabinet.apps.map(app => app.name),
                    }
                }

                if (key == 'disable_reason') {
                    template.cellEditor = 'agSelectCellEditor'
                    template.cellRenderer = 'DisableReasonRenderer';
                    template.cellEditorParams = {
                        values: [
                            'NONE',
                            'ADS_INTEGRITY_POLICY',
                            'ADS_IP_REVIEW',
                            'RISK_PAYMENT',
                            'GRAY_ACCOUNT_SHUT_DOWN',
                            'ADS_AFC_REVIEW',
                            'BUSINESS_INTEGRITY_RAR',
                            'PERMANENT_CLOSE',
                            'UNUSED_RESELLER_ACCOUNT'
                        ]
                    }
                }

                if (key == 'status') {
                    template.cellRenderer = 'CabinetStatusRenderer';
                    template.editable = false;
                }

                if (key == 'geo') {
                    template.cellRenderer = 'CountryRenderer';
                    template.cellEditor = 'agSelectCellEditor',
                        template.cellEditorParams = {
                            values: self.countries.sort(),
                        }
                }

                return template
            }).sort(function (a, b) {
                return a.index - b.index;
            });

            return columns;
        },
    },
    methods: {
        ...mapActions(['getCountries', 'hidePageModal']),
        updateAccount() {
            this.processUpdateAccount = true;
            axios
                .post('/dashboard/api/update-account', {
                    accounts: [this.account.id_dolphin]
                })
                .then(response => {
                    if (response.status == 200) {
                        this.refreshCabinets()
                    }
                })
                .catch(({response}) => {
                    if (response && response.status != 200) {
                        console.log(response.data);
                    }
                }).finally(() => {
                this.processUpdateAccount = false;

            });
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        },
        refreshCabinets() {
            axios
                .get('/dashboard/api/cabinets', {
                    params: {
                        account_id: this.account.id,
                    }
                })
                .then(response => {
                    if (response.status == 200) {
                        this.rowData = response.data;
                        this.gridApi.refreshHeader();
                        this.gridApi.redrawRows();
                    }
                });
        },
        setAccount() {
            window.livewire.emit('setActiveAccount', this.account.id)
        },
        copyArray(arr) {
            return JSON.parse(JSON.stringify(arr));
        },
        onCellValueChanged(params) {
            let field = params.colDef.field;
            let value = params.newValue;

            if ('pages' == field) {
                field = 'page_active';
                value = params.data.page_active;
            }

            if ('apps' == field) {
                field = 'app_active';
                value = params.data.app_active;
            }
            axios
                .post('/dashboard/cabinet/update', {
                    id: params.data.id,
                    field: field,
                    value: value,
                })
                .then(response => {
                    if (response.status == 200) {
                        const event = new CustomEvent('showToastr', {
                            detail: response.data
                        });
                        window.dispatchEvent(event)
                    }
                })
                .catch(error => {
                    if (error)
                        console.log(error);
                });
        },
        onColumnMoved(params) {
            let columns = params.columnApi.getColumnState();
            let columnsPosition = columns.map(column => column.colId);
            this.updateUserSetting('cabinets_columns_position', columnsPosition);
        },
        onColumnResized(params) {
            if (params.column !== null && params.finished) {
                let column = {};
                column[params.column.colId] = params.column.actualWidth;
                let columnsWidth = {...this.columnsWidth, ...column};
                this.updateUserSetting('cabinets_columns_width', columnsWidth);
            }
        },
        updateUserSetting(key, value) {
            axios
                .post('/dashboard/api/user/settings-update', {
                    key: key,
                    value: value,
                });
        },
    },
    watch: {
        isOpen(value) {
            this.updateUserSetting('cabinets_account_open_' + this.account.id, value);
        },
        columnDefs(value) {

        },


    },
    beforeMount() {
        if (!this.countries.length) this.getCountries();
    },
    mounted() {
        const self = this;
        window.addEventListener('accountSettingsUpdated', (e) => {

            if (e.detail.account_id == self.account.id) {
                self.acc_display = e.detail.acc_display;
                self.refreshCabinets();
            }
        });

        // setTimeout(() => {
        //     for (let key in self.columnsWidth){
        //         self.gridColumnApi.setColumnWidth({
        //             key:key,
        //             newWidth:self.columnsWidth[key]
        //         });
        //
        //     }
        // }, 1000);
    },
}
</script>

<style lang="scss">
.app-cabinets-list{
    .ag-center-cols-clipper {
        min-height: unset !important;
    }
}

.page-renderer-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.98);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__close {
        position: absolute;
        right: 10px;
        top: 10px;
        background: #eee;
        border: none;
        cursor: pointer;
        padding: 4px 6px;
        line-height: 1;
    }

    .pages-renderer {
        border-bottom: 1px dashed #ccc;
        margin-bottom: 10px;
        padding-bottom: 10px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__body {
        background: #ffffff;
        min-width: 300px;
        padding: 20px;
        border-radius: 10px;
        position: relative;


    }
}

.pages-renderer {
    display: flex;
    align-items: flex-start;
    column-gap: 6px;
    padding: 4px 0;
    line-height: 1;
    position: relative;

    &__badge {
        background: #28c76f;
        margin: 0 5px;
        line-height: 1;
        padding: 3px 6px;
        display: flex;
        align-items: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 9px;
        letter-spacing: .05em;
        border-radius: 10px;

        &.ban {
            background: #ea5455;
        }
    }

    &__status {
        width: 14px;
        margin-right: 6px;
    }

    &__id {
        font-size: 12px;
    }


    &__name {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        padding-right: 50px;
        line-height: -1;

    }

    img {
        width: 30px;
    }

}
</style>
