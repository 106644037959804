export default {
    template: `<span v-if="typeof statuses[params.value]!=='undefined'" class="status-for-renderer" :style="statuses[params.value]['style']">
    <vue-feather :type="statuses[params.value]['icon']" size="18"></vue-feather>
   </span>`,
    data: function () {
        return {
            statuses:{
                fb: {
                    icon: 'facebook',
                    style:{
                        color: 'var(--bs-cyan)'
                    }
                },

                mail: {
                    icon: 'mail',
                    style:{
                        color: 'var(--bs-purple)'
                    }
                },
            }
        }
    }
};
