export default {
    template: `<span style="display:flex;align-items:center;"><img :src="this.countryFlag" style="margin-right: 8px;" width="20" class="mr-1"  alt="" v-if="this.countryFlag">{{ countryCode }}</span>`,
    data: function () {
        return {
            countryCode: '',
            countryFlag: '',
        }

    },
    beforeMount() {
        if (this.params.value)
            axios.get('https://restcountries.com/v3.1/alpha/' + this.params.value)
                .then(response => {
                    if (response.status === 200 && response.data.length > 0) {
                        this.countryCode = response.data[0]['cca2'];
                        this.countryFlag = response.data[0]['flags']['svg'];
                    }
                })
    },
};
