import {mapActions} from "vuex";

export default {
    template: `
        <div class="pages-renderer__wrap">
        <div class="pages-renderer" v-if="firstPage">
            <img :src="firstPage.ava" :alt="firstPage.name" v-if="firstPage.ava">
            <div class="pages-renderer__body">
                <a target="_blank" class="pages-renderer__name">
                    <span>{{ firstPage.name }}</span>
                    <span class="pages-renderer__badge"
                          :class="{ban: firstPage.advertising_restriction===1}">{{ firstPage.advertising_restriction===1 ? 'бан' :'активная' }}</span>
                    <svg v-if="firstPage.is_published===1" viewBox="0 0 24 24" width="18" height="18"
                         stroke="#a6a4b0" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                         class="css-i6dzq1 pages-renderer__status">
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    <svg v-else viewBox="0 0 24 24" width="18" height="18" stroke="#a6a4b0" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 pages-renderer__status">
                        <path
                            d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                    </svg>

                </a>
                <div class="pages-renderer__id">{{ firstPage.page_id }}</div>
            </div>
        </div>
        </div>`,
    data: function () {
        return {
            firstPage: {},

        };
    },
    beforeMount() {
        this.firstPage = this.params.data.page_active
            ? this.params.value.find(page => page.id === this.params.data.page_active)
            : this.params.value[0];
        // console.log(this.params);
    },
    methods: {
        ...mapActions(['showPageModal']),
    }
};
