<template>
    <div class="form-check form-switch">
        <input type="checkbox" v-model="val" class="form-check-input" id="customSwitch1" />
    </div>
</template>

<script>
export default {
    name: "FormSwitcher",
    props:['value'],
    data:function (){
        return {
            val: this.value
        }
    }
}
</script>

<style scoped>

</style>
