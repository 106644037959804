export default {
    template: `<span><img :src="displayValue" width="36" :alt="params.value"/></span>`,
    data: function () {
        return {
            displayValue: ''
        };
    },
    beforeMount() {
        let images={
            'visa': 'https://img.icons8.com/color/48/000000/visa.png',
            'mastercard': 'https://img.icons8.com/color/48/000000/mastercard.png',
        }
        this.displayValue = images[this.params.value];

    },
};
