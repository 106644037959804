export default {
    template: `
        <Datepicker v-model="date" locale="ru-RU" format="dd.MM.yyyy HH:mm"/>`,
    name: "DatePickerRenderer",
    data: function () {
        return {
            date: null,


        };
    },

    methods: {
        getValue() {
            let date = this.date;
            let day = date.getDate().toString().padStart(2, '0');
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let year = date.getFullYear().toString();
            let hour = date.getHours().toString().padStart(2, '0');
            let minute = date.getMinutes().toString().padStart(2, '0');
            let second = date.getSeconds().toString().padStart(2, '0');
            return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
        },

    },
    beforeMount() {
        this.date = this.params.value;
    }
}


