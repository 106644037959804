export default {
    template: `
        <button class="badge" :class="badgeClass" style="border: none;">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             class="feather feather-star me-25">
            <polygon
                points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
        </svg>
        {{ columnValue }}
        </button>`,
    data: function () {
        return {
            columnName: null,
            columnValue: null
        }
    },
    computed: {
        badgeClass: function () {
            let columnName = this.columnName;
            let badgeClass = 'bg-secondary';
            if (columnName === 'acc_role') {
                switch (this.params.value) {
                    case 'main':
                        badgeClass = 'bg-primary';
                        break;
                }
            } else if (columnName === 'acc_type' || columnName === 'who_status') {
                switch (this.params.value) {
                    case 'PZRD':
                        badgeClass = 'bg-info';
                        break;
                    default:
                        badgeClass = 'bg-dark';
                        break;


                }
            } else if (columnName === 'status_pickup') {
                switch (this.params.value) {
                    case 'NEW':
                        badgeClass = 'bg-primary';
                        break;
                    case 'READY':
                        badgeClass = 'bg-success';
                        break;
                    case 'BAN':
                        badgeClass = 'bg-danger';
                        break;
                    case 'ERROR':
                        badgeClass = 'bg-warning';
                        break;
                    case 'USE':
                        badgeClass = 'bg-info';
                        break;
                    case 'USE_NOW':
                        badgeClass = 'bg-info';
                        break;

                }
            }

            return badgeClass;
        }
    },
    mounted() {
        this.columnName = this.params.colDef.field;
        this.columnValue = this.params.value;

    }

}
