export default {
    template: `<span v-if="params.value" class="geo-renderer">
    <img :src="params.data.geo_flag" v-if="params.data.geo_flag" class="mr-1">
    {{ params.value }}</span>`,
    data: function () {
        return {

        }
    },
    mounted() {

    }
};
