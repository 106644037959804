export default {
    template: `<span class="use-status-renderer" :class="{'text-success':params.value==='true','text-warning':params.value!=='true'}">
    <vue-feather :type="params.value==='true' ? 'check-circle' :'circle'" size="18"></vue-feather>
    </span>`,
    data: function () {
        return {
        }
    },
    beforeMount() {
    },
};
