export default {
    template: `<div class="d-flex align-items-center" v-if="params.value" style="height:100%;">
    <a :href="'/storage/files/'+params.value"
        class="btn btn-outline-info btn-sm"
        style="text-overflow: ellipsis; display: block; overflow: hidden;"
        target="_blank">{{ params.value }}</a></div>`,
    mounted() {

    }
}
