import {createStore, createLogger} from 'vuex'

export default createStore({
    state() {
        return {
            countries: [],
            pages: [],
            showPageModal: false,
        }
    },
    getters: {
        countries: state => state.countries,
        pages: state => state.pages,
        showPageModal: state => state.showPageModal,
    },
    mutations: {
        setCountries(state, countries) {
            state.countries = countries;
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setShowPageModal(state, showPageModal) {
            state.showPageModal = showPageModal;
        }

    },
    actions: {
        showPageModal({commit}, pages) {
            commit('setPages', pages);
            commit('setShowPageModal', true);
        },
        hidePageModal({commit}) {
            commit('setShowPageModal', false);
        },
        getCountries({commit}) {
            let countries = [];
            axios
                .get('https://restcountries.com/v3.1/all')
                .then(response => {
                    if (response.status == 200) {
                        response.data.forEach(country => {
                            countries.push(country.cca2);
                        });
                        commit('setCountries', countries.sort());
                    }
                });


        }
    }
})
